
import { getToken, setToken, removeToken } from '@/utils/auth'
import { getLogin,postWxLogin,postWxSupperLogin } from "@/api/auth";
import { addRoutes } from '@/router';
import { ButtonListMySelf } from "@/api/role";
import { treeToList } from '@/utils';
import { postCalllogsIn } from '@/api/call';
const state = {
  token: getToken('token'),
  menus: [],
  planOrderPermission:[],
  tableThList: [],//自定义表头
  filterList:{},//自定义筛选
  hostConfig: getToken('hostConfig'),
  pageButtons:[],
  orderInfoData:{},//下单信息
  addOrderfromData:{},//下单新增用户信息，收货信息响应
  topCssBoolen:true,//顶部导航 铃铛动画.
  changeMenusData:0,
}

const mutations = {
  setTableTh:(state,data)=>{
    state.tableThList = data;
  },
  setFilter:(state,data)=>{
    state.filterList = data;
  },
  // 路由值变化时，改变changeMenusData
  setMenusData:(state,data)=>{
    state.changeMenusData = data;
  },
  setToken: (state, token) => {
    state.token = token;
    setToken('token',token) 
  },
  setUser: (state, hostConfig) => {
    state.hostConfig = hostConfig;
    setToken('hostConfig',hostConfig);
  },
  setMenus: (state, menus) => {
    state.menus = menus
  },
  setPlanOrderPermission: (state, menus) => {
    state.planOrderPermission = menus
  },
  setPageButons: (state, buttons) => {
    state.pageButtons = buttons
  },
  setOrderInfoData:(state,data)=>{ //下单信息
    state.orderInfoData = data
  },
  setAddOrderfromData:(state,data)=>{ //下单新增用户信息
    state.addOrderfromData = data 
  },
} 
const actions = {
  // 自定义表格
  actTableTh({commit},data){
    commit('setTableTh',data)
  },
  // 自定义筛选
  actFilter({commit},data){
    commit('setFilter',data)
  },
  getOrderInfoData({commit},data){ //下单信息
    console.log('getOrderInfoData',data)
    commit('setOrderInfoData',data)
  },
  getAddOrderfromData({commit},data){ //下单新增用户信息
    commit('setAddOrderfromData',data)
  },
  async login({ commit }, paras) { 
    let res 
    if(paras.wxType && paras.wxType =='wxlogin'){
      // 微信扫码登录
      delete paras.wxType
      res= await postWxLogin(paras); 
    }else if(paras.wxType =='wxsuplogin'){
      delete paras.wxType
      res= await postWxSupperLogin(paras); 
      
    }else{
      // 普通账号登录
      res= await getLogin(paras);
    }
    
    // ?.code == 1
    if (res.Success) {
      const token = res.Data.Token;
      const hostConfig = JSON.stringify(res.Data);
      commit('setToken', token);
      commit('setUser', hostConfig); 
    }
    return res
  },

async getLoginInfo({ commit }) {
    // 获取登录用户的菜单
    let roleId = JSON.parse(state.hostConfig).RoleId; 
    let res = await ButtonListMySelf({
      Id:roleId,
      IsChecked:true,
    });
    let d = res.Data.map(item=>{
      if(!item.ParentId){
        item.ParentId = '0';
      }
      return item;
    });
    let list = treeToList(d,null,'Menus','Id','ParentId');
    //路由菜单数据
    let rootMenu = [
      {
        Id: "1",
        ParentId: null,
        icon:'companySupervise',
        Name: '基础配置',
        opened:false,
        Visible:true,
        Url:null,
      },  
      {
        Id: "1-1",
        ParentId: "1",
        icon:'',
        Name: '用户管理',
        opened:false,
        Visible:true,
        Url:'/userManage',
      },{
        Id: "1-5",
        ParentId: "1",
        icon:'',
        Name: '菜单管理',
        opened:false,
        Visible:true,
        Url:'/menu',
      },{
        Id: "1-2",
        ParentId: "1",
        icon:'',
        Name: '角色权限',
        opened:false,
        Visible:true,
        Url:'/role',
      },{
        Id: "1-3",
        ParentId: "1",
        icon:'',
        Name: '站点管理',
        opened:false,
        Visible:true,
        Url:'/siteManagement',
      },{
        Id: "1-4",
        ParentId: "1",
        icon:'',
        Name: '商品管理',
        opened:false,
        Visible:true,
        Url:'/commodityManagement',
      },
      {
        Id: "2",
        ParentId: null,
        icon:'companySupervise',
        Name: '销售管理', 
        opened:false,
        Visible:true,
        Url:null,
      }, {
        Id: "2-1",
        ParentId: "2",
        icon:'',
        Name: '燃气用户',
        opened:false,
        Visible:true,
        Url:'/gasUser',
      }, 
      {
        Id: "2-2",
        ParentId:  "2",
        icon:'companySupervise',
        Name: '通讯记录',
        opened:false,
        Visible:true,
        Url:'/CommunicationRecords',
      },{
        Id: "2-3",
        ParentId:  "2",
        icon:'companySupervise',
        Name: '订单管理',
        opened:false,
        Visible:true,
        Url:'/orderManagement',
      }, {
        Id: "2-4",
        ParentId:  "2",
        icon:'companySupervise',
        Name: '押瓶退瓶',
        opened:false,
        Visible:true,
        Url:'/retreatPressureGasCylinder',
      }, {
        Id: "2-5",
        ParentId:  "2",
        icon:'companySupervise',
        Name: '安检管理',
        opened:false,
        Visible:true,
        Url:'/safetyInspection',
      }, {
        Id: "2-6",
        ParentId: "2",
        icon:'companySupervise',
        Name: '报修管理',
        opened:false,
        Visible:true,
        Url:'/repairRequest',
      },
      // {
      //   id: "2-7",
      //   parentId: "2",
      //   icon:'companySupervise',
      //   Name: '下单管理',
      //   opened:false,
      //   Visible:true,
      //   Url:'/placeAnOrder',
      // } 
    ]
    commit('setMenus', list);
    // 设置下单页面的权限
    let planOrderPermission = list.filter(item=>item.Url == '/nourl')[0]?.Buttons?.map(citem=>citem.Name);
    commit('setPlanOrderPermission', planOrderPermission?planOrderPermission:[]);
    addRoutes(list);
    return list
  },

  logout({ commit }) {
    commit('setToken', '');
    commit('setMenus', '');
    removeToken('token');
    window.localStorage.clear()
  },
  
  // 关联电话下单
  async callbindCus({ commit }, cus,) {
    let callid = window.localStorage.getItem('callid'+cus.phone); // 获取通话ID
    console.log('关联电话下单0==0',cus,callid);
    if(callid){
      try {
        let glres = await postCalllogsIn({
          Callid: callid,
          CustomerId: cus.id
        });
        console.log('关联电话下单1==',glres);
      } catch (error) {}
    }
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
