/*
 * @Description: 
 * @Author: MasterGehong
 * @Date: 2022-06-13 17:00:10
 * @LastEditors: MasterGehong
 * @LastEditTime: 2024-08-08 14:42:57
 * @FilePath: \src\api\order.js
 */
import request from '@/utils/request'
const apiPrefix = `${process.env.VUE_APP_BASE_API}/Order/`

const qs = require('qs');
// 订单管理

//  列表
export const getOrderList = (params, config = {}) => {
    // 订单状态为数组类型的时候
    if(params.Status&&params.Status.length>0){
        let status =qs.stringify({Status:params.Status}, {arrayFormat:'repeat'})
        let setPatams =  JSON.parse(JSON.stringify(params))
        delete setPatams.Status
        return request.get(apiPrefix + 'List?'+status, { params: setPatams, ...config })
    }
    return request.get(apiPrefix + 'List', { params: params, ...config })
}
// 历史订单列表 api/Order/History
export const getOrderHistory = (params, config = {}) => {
    return request.get(apiPrefix + 'History', { params: params, ...config })
}
// 新增
export const postOrderAdd = (params, config = {}) => {
    return request.post(apiPrefix + 'Add', params, config)
} 
// 订单详情
export const detailOrder = (params, config = {}) => {
    return request.get(apiPrefix + 'SingleDetail', { params: params, ...config })
}
// 订单修改
export const UpdateOrder = (params, config = {}) => {
    return request.post(apiPrefix + 'UpdateOrder', params, config)
}
// 获取订单快照
export const GetOrderCopy = (params, config = {}) => {
    return request.get(apiPrefix + 'GetOrderCopy', { params: params, ...config })
}
// 订单删除
export const deleteOrder = (params, config = {}) => { 
    return request.delete(apiPrefix + 'Delete', { params: params, ...config })
} 
// 订单完成
export const FinishOrder = (params, config = {}) => { 
    return request.put(apiPrefix + 'Finish', { params: params, ...config })
} 
// 订单配送
export const DeliverOrder = (params, config = {}) => { 
    return request.put(apiPrefix + 'Deliver', { params: params, ...config })
} 
// 撤单
export const QuashOrder = (params, config = {}) => { 
    return request.put(apiPrefix + 'Quash', params, config )
} 
// 派单
export const AssignOrder = (params, config = {}) => { 
    return request.put(apiPrefix + 'Assign', params, config )
}  
// 批量派单
export const AssignBatchOrder = (params, config = {}) => { 
    return request.post(apiPrefix + 'AssignBatch', params, config )
}  
// 转派
export const Reassign = (params, config = {}) => { 
    return request.put(apiPrefix + 'Reassign', params, config )
}  
// 结单
export const OverFinish = (params, config = {}) => { 
    return request.put(apiPrefix + 'FinishOrder', params, config )
}  
// 催单
export const OrderRemind = (params, config = {}) => { 
    return request.put(apiPrefix + 'OrderRemind', params, config )
}

// 南洋打印 /api/Order/GetNTHYOrderPrintData
export const GetNTHYOrderPrintData = (params, config = {}) => { 
    return request.get(apiPrefix + 'GetNTHYOrderPrintData',{ params:params, ...config} )
}  
// 南洋打印 /api/Order/GetNTHYOrderPrintData
export const GetJSSAordexPrinting = (params, config = {}) => { 
    return request.get(apiPrefix + 'JSSAOrderPrinting',{ params:params, ...config} )
}
// 历史拒单
export const GetRefuseQuery = (params, config = {}) => { 
    return request.get(apiPrefix + 'RefuseQuery',{ params:params, ...config} )
}
//导出
export const exportExl = (params, config = {}) => { 
    return request.get(apiPrefix + 'ExportListToExcel',{ params:params, ...config} )
 
}  
// 上一次订单
export const getLastOrder = (params, config = {}) => {
    return request.get(apiPrefix + 'LastOrder', { params: params, ...config })
}
// 微信受理下单
export const postOrderAddWx= (params, config = {}) => {
    return request.post(apiPrefix + 'AddWx', params, config)
} 

// 按重量下单和规格末次价格
export const postPriceByCylinderSpec= (params, config = {}) => {
    return request.post(apiPrefix + 'GetPriceByCylinderSpec', params, config)
} 

// 优洁能 批量结单功能
export const postsettleOrders= (params, config = {}) => {
 return request.post(apiPrefix+'SettleOrders', params, config)
}
// 结单获取汇总数据
export const getSettleOrderSum= (params, config = {}) => {
    let status =qs.stringify({OrderId:params.OrderId}, {arrayFormat:'repeat'})
    return request.get(apiPrefix+'SettleOrderSum?'+status, { params: {}, ...config })
}
// 修改条码
export const getChangeBarcode= (params, config = {}) => {
    return request.put(apiPrefix+'ChangeBarcode',  params, config)
}

    // 删除
export const getDelete= (params, config = {}) => {
    return request.delete(apiPrefix+'Delete' ,{ params: params, ...config })
}

    // 订单流转
    export const getFolOrderHistory= (params, config = {}) => {
        return request.get(apiPrefix+'OrderHistory' ,{ params: params, ...config })
}

// 溧阳订单打印
export const lyOrderPrinting = (params, config = {}) => {
    return request.get(apiPrefix+'OrderPrinting' ,{ params: params, ...config })
}
// 海安打印
export const hALJOrderPrinting  = (params, config = {}) => {
    return request.get(apiPrefix+'HALJOrderPrinting' ,{ params: params, ...config })
}
// 批量开票
export const putDrawerOrder  = (params, config = {}) => {
    return request.put(apiPrefix+'DrawerOrder' , params, config)
}
// 撤销开票
export const putRepealDrawerOrder  = (params, config = {}) => {
    return request.put(apiPrefix+'RepealDrawerOrder' , params, config)
}
// 全部批量/撤销
export const putAllDrawerOrder  = (params, config = {}) => {
    return request.put(apiPrefix+'AllDrawerOrder' , params, config)
}
// 扬州信达订单开票
export const putXdNuoShuiTongBill  = (params, config = {}) => {
    return request.put(apiPrefix+'XdNuoShuiTongBill' , params, config)
}