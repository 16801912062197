<!-- 
  示例：
  <ApiUPImg  
    ref="uploadImage"  
    :limit="1"
    fileTypeName="forklift"
    :bucketName="'jsxk-forklift'"
    :urlList="IdentificationPhoto"
    @getUrlList="getUploadList"
    @removeItemUrl="removeItemUrl"
  ></ApiUPImg>
  fileTypeName :支持多路径拼接 a/b/c
 -->
<template>
  <div class="image-uploader">
    <ul class="el-upload-list">
      <li v-for="(url, i) in imageList" :key="url" class="el-upload-image" :style="{ width: imageW + 'px', height: imageW + 'px' }">
        <el-image :src="url" :preview-src-list="imageList">
          <div slot="error" class="image-slot">
            <img width="100%" src="" />
          </div>
        </el-image>
        <i class="el-icon-close" v-if="limit > 0 && show" @click="handleRemove(i)"></i>
      </li>
      <li v-if="show" class="el-upload-add" :class="{ 'el-upload-solid': (!showAddBtn && limit > urlList.length) || showAddBtn }" :style="{ width: (!showAddBtn&& limit > urlList.length ? imageW : 0) + 'px', height: imageW + 'px' }">
        <el-upload
          v-loading="avatarLoading"
          class="image-uploader"
          ref="upload"
          action="#"
          :data="{ path: fileTypeName, bucketName: bucketName }"
          accept="image/png,image/jpg,image/jpeg,image/gif"
          multiple
          :http-request="setUploadImage"
          :file-list="fileList"
          :limit="limit"
          :show-file-list="false"
          :before-upload="handleBeforeUpload"
          :on-exceed="handleExceed"
          :on-success="onAvatarSuccess"
          :on-error="onAvatarError"
          :on-change="handleChange"
          style="display: inline-block"
        >
          <template v-if="showAddBtn"> <i slot="default" class="el-icon-plus"></i>添加</template>
          <template v-else>
            <div v-if="limit > urlList.length"><i slot="default" class="el-icon-plus"></i>添加</div>
          </template>
          <template #error>
            <img :src="avatarDefault" />
          </template>
        </el-upload>
      </li>
    </ul>
    <div class="el-upload__tip" v-if="showTip">
      允许上传jpg、png、gif格式的图片，且只能上传{{ limit }}张图片，大小不超过{{ limitSize }}MB
      <span v-if="type == 1"> 轮播图尺寸为<span style="color:red">375px*150px或等比例图片</span>,其他尺寸将会影响效果展示。</span>
    </div>
    <div class="label-title" v-if="title">{{ title }}</div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { postUploadImage } from '@/api/oss.js';
export default {
  name: 'UploadImage',
  props: {
    type: {
      type: String,
      default: '0',
    },
    urlList: {
      type: Array,
      default: () => [],
    },
    showAddBtn: {
      type: Boolean,
      default: true,
    },
    bucketName: {
      type: String,
      default: 'jsxk-cylinderpledge-bill',
    },
    fileTypeName: {
      type: String,
      default: 'callAdmin',
    },
    value: {
      type: String,
      default: '',
    },
    limit: {
      type: Number,
      default: 5,
    },
    limitSize: {
      type: Number,
      default: 1,
    },
    imageW: {
      type: Number,
      default: 90,
    },
    // 显示特定部分，隐患整改使用
    show: {
      type: Boolean,
      default: true,
    },
    // 是否显示最少上传图片数量（如苏州缠绕气瓶图片增加）
    showMinLimit: {
      type: Boolean,
      default: false,
    },
    showTip: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      uploadimageapi: '/upload',
      fileList: [],
      avatarLoading: false,
      avatarDefault: require('@/assets/images/avatar.png'),
      fileInfo: {},
      photoUrl: '',
    };
  },
  computed: {
    ...mapGetters(['hostConfig']),
    imageList() {
      return [...this.urlList];
    },
    // 控制图片最多5张（）
    imageLimit() {
      return this.limit - this.urlList.length;
    },
  },
  methods: {
    handleChange(file) {
      console.log('filechange:', file.response);
    },
    // 图片数量限制
    handleExceed(file, fileList) {
      console.log('fileList', fileList);
      this.$message.error(`最多上传${this.limit}张`);
    },
    // 上传之前
    async handleBeforeUpload(file, fileList) {
      console.log('上传之前', file, fileList);
      // 文件后缀 限制
      const fileSuffix = file.name.substring(file.name.lastIndexOf('.') + 1);
      const whiteList = ['png', 'jpg', 'jpeg', 'gif'];
      if (whiteList.indexOf(fileSuffix) === -1) {
        this.$message.error('上传图片只能是 png、jpg、jpeg、gif格式');
        return false;
      }
      // 文件大小 限制
      const isLt1M = file.size / 1024 / 1024 < this.limitSize;
      if (!isLt1M) {
        this.$message.error(`上传文件大小不能超过${this.limitSize}M!`);
        this.avatarLoading = false;
        return false;
      }
      // 文件数量限制
      if (this.imageLimit == 0) {
        this.$message.error(`最多上传${this.limit}张`);
        return false;
      }
      this.avatarLoading = true;
      return true;
    },
    async setUploadImage(file) {
      console.log(file);
      // 自定义接口上传
      var data = new FormData();
      data.append('file', file.file);
      return await postUploadImage(data);
    },
    // 上传成功
    onAvatarSuccess(res, file, fileList) {
      this.$refs.upload.clearFiles();
      if (this.limit == 1) this.fileList.splice(0, 1);
      this.$emit('getUrlList', res.Data,this.urlList);
      this.avatarLoading = false;
      console.log('上传成功', res);
    },
    // 上传失败
    onAvatarError(err, file) {
      console.log(err);
    },
    // 删除图片
    handleRemove(index) {
      this.$refs.upload?.uploadFiles.splice(index, 1);
      this.$emit('removeItemUrl', index,this.urlList);
    },
  },
  created() {
    this.photoUrl = process.env.VUE_APP_PHOTOURLTEST;
  },
};
</script>

<style lang="scss" scoped>
.el-upload-list {
  display: flex;
  flex-wrap: wrap;
}
.el-upload-image {
  display: flex;
  justify-content: center;
  align-items: center;
}
.el-upload-image,
.el-upload-add {
  margin-right: 10px;
  margin-bottom: 10px;
}
.el-upload-image {
  position: relative;
  .el-image {
    max-width: 100%;
    max-height: 100%;
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
  .el-icon-close {
    box-sizing: border-box;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    width: 30px;
    height: 30px;
    line-height: 25px;
    font-size: 14px;
    font-weight: bold;
    text-align: right;
    padding-right: 5px;
    color: #333;
    background-color: #f2f2f2;
    border-radius: 0px 0px 0px 30px;
  }
}
.el-upload-solid {
  border: 1px #ccc dashed;
}
.el-upload-add {
  display: flex;
  justify-content: center;
  align-items: center;
}
.el-upload__tip {
  margin-top: 0;
  margin-left: 0;
  color: #999;
}
.label-title{
line-height: 14px;
font-size: 14px;
}
</style>
