/*
 * @Description: 
 * @Author: wangjieqiong
 * @Date: 2024-12-09 09:32:16
 * @LastEditors: wangjieqiong
 * @LastEditTime: 2024-12-09 10:11:39
 * @FilePath: \src\store\modules\tag.js
 */

const state = {
    visitedRoutes: JSON.parse(localStorage.getItem('caughtRoutes')) || [],
    caughtRoutes: [],
}
const mutations = {
    // 添加tag
    addVisitedRoute: (state, route) => {
        const target = state.visitedRoutes.find((item) => item.path === route.path)
        if (target) Object.assign(target, route)
        else state.visitedRoutes.push(Object.assign({}, route))
    },

    // 删除tag
    delVisitedRoute(state, path) {
        state.visitedRoutes = state.visitedRoutes.filter((route) => route.path !== path)
    },

    // 删除当前标签页以外其它全部标签页
    delOthersVisitedRoutes(state, path) {
        state.visitedRoutes = state.visitedRoutes.filter((route) => route.meta.noClosable || route.path === path)
    },

    // 删除当前标签页左边全部标签页
    delLeftVisitedRoutes(state, path) {
        let found = false
        state.visitedRoutes = state.visitedRoutes.filter((route) => {
            if (route.path === path) found = true
            return route.meta.noClosable || found
        })
    },

    // 删除当前标签页右边全部标签页
    delRightVisitedRoutes(state, path) {
        let found = false
        state.visitedRoutes = state.visitedRoutes.filter((route) => {
            const close = found
            if (route.path === path) found = true
            return route.meta.noClosable || !close
        })
    },

    // 清空标签页
    clearVisitedRoutes(state) {
        state.visitedRoutes = []
    },

    handleCaughtRoutes: (state) => {
        alert('0')
        localStorage.setItem('caughtRoutes', JSON.stringify(state.visitedRoutes))
    }
}
const actions = {

}

export default {
    namespaced: true,
    state,
    mutations,
    actions
  }