import request from '@/utils/request';

const apiPrefix = `${process.env.VUE_APP_BASE_API}`;
// 订单来源
const getOrderSourceList = (params, config = {}) => {
  return request.get(apiPrefix + '/DropDownList/OrderSourceList', { params: params, ...config });
};
// 获取使用单位下拉框
const getTextRegulator = (params, config = {}) => {
  return request.get(apiPrefix + '/Admin/DropDown/UnitAndRegulatorGetDropListAsync', { params: params, ...config });
};
// 获取人员下拉框
const getEmployee = (params, config = {}) => {
  return request.get(apiPrefix + '/Admin/DropDown/EmployeeAndSupervisorGetDropListAsync', { params: params, ...config });
};
// 获取角色下拉框
const getRoleTextValve = (params, config = {}) => {
  return request.get(apiPrefix + '/Admin/DropDown/RoleGetDropListAsync', { params: params, ...config });
};

// 站点下拉框
export const getStationList = (params, config = {}) => {
  return request.get(apiPrefix + '/DropDownList/StationList', { params: params, ...config });
};
// 站点下拉框-不限制當前帳號
export const getStationListWithNoLimit = (params, config = {}) => {
  return request.get(apiPrefix + '/DropDownList/StationListWithNoLimit ', { params: params, ...config });
};
// 商品分类下拉框
const getProductClassify = (params, config = {}) => {
  return request.get(apiPrefix + '/DropDownList/ProductClassify', { params: params, ...config });
};

// 角色下拉列表
const getRoletList = (params, config = {}) => {
  return request.get(apiPrefix + '/Role/List', { params: params, ...config });
};
// 气站信息
const getStoreListAsync = (params, config = {}) => {
  return request.get(apiPrefix + '/DropDownList/StoreList', { params: params, ...config });
};
// 客户类型信息
export const getCustomerTypeListAsync = (params, config = {}) => {
  return request.get(apiPrefix + '/DropDownList/CustomerTypeList', { params: params, ...config });
};
// 非居民细分类型
const getCustomerSubTypeList = (params, config = {}) => {
  return request.get(apiPrefix + '/DropDownList/CustomerSubTypeList', { params: params, ...config });
};
// 居民细分
const getResidentSubTypeList = (params, config = {}) => {
  return request.get(apiPrefix + '/DropDownList/ResidentSubTypeList', { params: params, ...config });
};
// 获取区划
export const getDivisionListCode = (params, config = {}) => {
  return request.get(apiPrefix + '/DropDownList/DivisionList', { params: params, ...config });
};
// 筛选配送工
const gePstList = (params, config = {}) => {
  return request.get(apiPrefix + '/DropDownList/OperatorList', { params: params, ...config });
};
// 配送工下拉框
const getOperatorList = (params, config = {}) => {
  return request.get(apiPrefix + '/DropDownList/OperatorList', { params: params, ...config });
};
// 获取所有配送工
const getAllOperatorList = (params, config = {}) => {
  return request.get(apiPrefix + '/DropDownList/GetAllOperatorList', { params: params, ...config });
};
// 气瓶规格
const getSeletCylinderPledgePriceList = (params, config = {}) => {
  return request.get(apiPrefix + '/CylinderPledge/GetCylinderPledgeSpecList', { params: params, ...config });
};
// 客户
const getCustomerDown = (params, config = {}) => {
  return request.get(apiPrefix + '/Customer/List', { params: params, ...config });
};
// 站点类型
const getClassify = () => {
  return {
    code: 1,
    Data: [
      {
        Id: 0,
        Name: '公司',
      },
      {
        Id: 1,
        Name: '气站',
      },
      {
        Id: 2,
        Name: '门店',
      },
    ],
  };
};

// 小白盒返回支付方式
const getTradeType = () => {
  return {
    code: 1,
    Data: [
      {
        Id: 1,
        Name: 'e融支付',
      },
      {
        Id: 2,
        Name: '微信',
      },
      {
        Id: 3,
        Name: '支付宝',
      },
      {
        Id: 4,
        Name: '银联',
      },
    ],
  };
};

const getRentChargeUnit = () => {
  return {
    code: 1,
    Data: [
      // {
      //   Id: 1,
      //   Name: '按天收取',
      // },
      {
        Id: 2,
        Name: '按月收取',
      },
      {
        Id: 3,
        Name: '按年收取',
      },
    ],
  };
};

// 维修单状态
const getRepairStatus = () => {
  return {
    code: 1,
    Data: [
      {
        Id: 0,
        Name: '已撤销',
      },
      {
        Id: 1,
        Name: '待指派',
      },
      {
        Id: 2,
        Name: '待维修',
      },
      {
        Id: 3,
        Name: '维修中',
      },
      {
        Id: 4,
        Name: '已完成',
      },
    ],
  };
};

// 获取省地址下拉列表 DropDownList/DivisionList
export const getDivisionList = (params, config = {}) => {
  return request.get(apiPrefix + '/DropDownList/DivisionList', { params: params, ...config });
};
// 通过气站id获取配送人员列表
export const getStationOperList = (params, config = {}) => {
  return request.get(apiPrefix + '/DropDownList/GetOperatorListByStation', { params: params, ...config });
};
// 跟据配置获取回收瓶回收人员列表
export const getCylinderRecycleUserListByStation = (params, config = {}) => {
  return request.get(apiPrefix + '/DropDownList/GetCylinderRecycleUserListByStation', { params: params, ...config });
};
// 商品下拉框
export const getProduct = (params, config = {}) => {
  return request.get(apiPrefix + '/DropDownList/Product', { params: params, ...config });
};
// 业务员下拉
export const getSalesmanList = (params, config = {}) => {
  return request.get(apiPrefix + '/DropDownList/SalesmanList', { params: params, ...config });
};
// 发放用户优惠券
export const postCouponList = (params, config = {}) => {
  return request.get(apiPrefix + '/DropDownList/CouponList', { params: params, ...config });
};
 
// .net 从业人员下拉
export const getSettleList = (params, config = {}) => {
  return request.get(apiPrefix + '/DropDownList/SettlerList', { params: params, ...config });
};
// JAVa接口
// 从业人员下拉框
export const getJavaUserSelect = (params, config = {}) => {
  return request.get('/javaApi/Order/getUserSelect', { params: params, ...config });
};

// 城中村改造状态
export const getVillageTransformStatusList = (params, config = {}) => {
  return request.get(apiPrefix + '/DropDownList/VillageTransformStatusList', { params: params, ...config });
};

export default {
  getAllOperatorList,
  getTextRegulator,
  getEmployee,
  getRoleTextValve,
  getStationList,
  getProductClassify,
  getRoletList,
  getStoreListAsync,
  getCustomerTypeListAsync,
  getCustomerSubTypeList,
  getDivisionListCode,
  getClassify,
  gePstList,
  getOperatorList,
  getRepairStatus,
  getSeletCylinderPledgePriceList,
  getStationOperList,
  getProduct,
  getSalesmanList,
  postCouponList,
  getOrderSourceList,
  getCustomerDown,
  getJavaUserSelect,
  getResidentSubTypeList,
  getCylinderRecycleUserListByStation,
  getSettleList,
  getTradeType,
  getRentChargeUnit,
  getVillageTransformStatusList
};
