/*
 * @Description: 
 * @Author: MasterGehong
 * @Date: 2022-06-09 10:35:56
 * @LastEditors: wangjieqiong
 * @LastEditTime: 2024-12-09 09:37:16
 * @FilePath: \src\store\getters.js
 */
const getters = {
    menus:state=>state.user.menus,
    planOrderPermission:state=>state.user.planOrderPermission,
    tableThList:state=>state.user.tableThList,
    filterList:state=>state.user.filterList,
    token:state=>state.user.token,
    hostConfig:state=>JSON.parse(state.user.hostConfig),
    pageButtons:state=>state.user.pageButtons,
    gettOrderInfoData:state=>state.user.orderInfoData,
    addOrderfromData:state=>state.user.addOrderfromData,
    changeMenusData:state=>state.user.changeMenusData,
    visitedRoutes: (state) => state.tag.visitedRoutes,
    // // 获取当前路由
    // currentRoute: (state) => state.app.currentRoute,
    // // 获取当前路由的父级路由
    // currentParentRoute: (state) => state.app.currentParentRoute,
    // // 获取当前路由的子路由
    // currentChildRoute: (state) => state.app.currentChildRoute,
    // // 获取当前路由的子路由
}
export default getters