<template>
  <div class="tabs-box">
    <el-tabs v-model="activeName" @tab-click="handleTabClick" @tab-remove="handleTabRemove">
      <el-tab-pane v-for="item in visitedRoutes" :key="item.path" :label="item.meta.title" :name="item.path" :closable="visitedRoutes.length > 1">
        <span slot="label" style="display: flex; align-items: center">
          <span :class="'menu-' + item.path.split('/')[1]" style="margin-right: 5px"></span>
          {{ item.meta.title }}
        </span>
      </el-tab-pane>
    </el-tabs>
    <el-dropdown @visible-change="handleVisibleChange">
      <span class="vab-tabs-more" :class="{ 'vab-tabs-more-active': active }">
        <span class="vab-tabs-more-icon">
          <i class="box box-t"></i>
          <i class="box box-b"></i>
        </span>
      </span>
      <el-dropdown-menu slot="dropdown" class="tabs-more">
        <el-dropdown-item @click.native="closeOthersTabs">关闭其他</el-dropdown-item>
        <el-dropdown-item @click.native="closeLeftTabs">关闭左侧</el-dropdown-item>
        <el-dropdown-item @click.native="closeRightTabs">关闭右侧</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>

    <!-- 右键菜单 -->
    <div v-if="showMenu" class="context-menu" :style="{ top: menuTop + 'px', left: menuLeft + 'px' }">
      <ul>
        <li @click="closeOthersTabsRight">关闭其他</li>
        <li @click="closeLeftTabsRight">关闭左侧</li>
        <li @click="closeRightTabsRight">关闭右侧</li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'TagsView',
  components: {},
  data() {
    return {
      activeName: '',
      active: false,
      showMenu: false,
      menuTop: 0,
      menuLeft: 0,
      path: '',
    };
  },
  computed: {
    ...mapGetters(['visitedRoutes']),
  },
  watch: {
    '$route.fullPath': {
      handler: function(to) {
        console.log('路由变化', to,to.split('/').length);
        if (to.split('/').length == 2) {
          const tab = {
            path: this.$route.path,
            query: this.$route.query,
            params: this.$route.params,
            name: this.$route.name,
            meta: { ...this.$route.meta },
          };
          this.$store.commit('tag/addVisitedRoute', tab);
          this.activeName = tab.path;
        }
      },
      immediate: true,
    },
  },
  methods: {
    handleTabClick(tab) {
      if (tab.name !== this.$route.path) {
        this.$router.push({ path: tab.name });
      }
    },

    handleTabRemove(tab) {
      if (tab === this.$route.path) {
        const index = this.visitedRoutes.findIndex((item) => item.path === tab);
        if (index > 0) {
          this.$router.push({ path: this.visitedRoutes[index - 1].path });
        } else if (index < this.visitedRoutes.length - 1) {
          this.$router.push({ path: this.visitedRoutes[index + 1].path });
        }
      }
      this.$store.commit('tag/delVisitedRoute', tab);
    },

    handleVisibleChange(value) {
      this.active = value;
    },

    showContextMenu(path, event) {
      this.path = '';
      this.path = path;

      event.preventDefault(); // 阻止默认的右键菜单
      this.showMenu = true;
      this.menuTop = event.clientY;
      this.menuLeft = event.clientX;
    },

    closeContextMenu() {
      this.showMenu = false;
    },

    closeOthersTabs() {
      this.$store.commit('tag/delOthersVisitedRoutes', this.activeName);
      this.closeContextMenu();
    },

    closeLeftTabs() {
      this.$store.commit('tag/delLeftVisitedRoutes', this.activeName);
      this.closeContextMenu();
    },

    closeRightTabs() {
      this.$store.commit('tag/delRightVisitedRoutes', this.activeName);
      this.closeContextMenu();
    },

    closeOthersTabsRight() {
      if (this.path != this.$route.path) {
        this.$router.push({ path: this.path });
        return;
      }
      this.$store.commit('tag/delOthersVisitedRoutes', this.activeName);
      this.closeContextMenu();
    },
    closeLeftTabsRight() {
      console.log(this.path);

      if (this.path != this.$route.path) {
        this.$router.push({ path: this.path });
        return;
      }
      this.$store.commit('tag/delLeftVisitedRoutes', this.activeName);
      this.closeContextMenu();
    },
    closeRightTabsRight() {
      if (this.path != this.$route.path) {
        this.$router.push({ path: this.path });
        return;
      }
      this.$store.commit('tag/delRightVisitedRoutes', this.activeName);
      this.closeContextMenu();
    },

    restoreRoutes(){
        this.$store.commit('tag/handleCaughtRoutes')
    }
  },
  created() {
    window.addEventListener('beforeunload', this.restoreRoutes);
  },
  mounted() {
  },
  beforeDestroy() {
    // document.removeEventListener('click', this.closeContextMenu);
    window.removeEventListener('beforeunload', this.restoreRoutes);
  },
};
</script>

<style lang="scss" scoped>
.tabs-box {
  width: 100%;
  min-height: 50px;
  background: #fff;
  padding: 0 12px 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  ::v-deep .el-tabs {
    width: calc(100% - 30px);
    height: 100%;
    display: flex;
    align-items: center;

    .el-tabs__nav {
      display: flex;
    }

    .el-tabs__header {
      width: 100%;
      margin: 0;

      .el-tabs__item {
        height: 34px;
        margin-right: 5px;
        border: 1px solid #dcdfe6 !important;
        border-radius: 5px !important;
      }
    }

    &__nav-wrap::after {
      background: none;
    }

    &__item {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-right: 12px !important;
      padding-left: 12px !important;

      .is-icon-close {
        width: 14px !important;
        margin-top: 1px;
        margin-right: 0 !important;

        svg {
          margin-right: 0 !important;
        }
      }
    }

    &__active-bar {
      display: none;
    }

    &__nav-next,
    &__nav-prev {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 34px;
    }
  }

  .vab-tabs-more {
    position: relative;
    box-sizing: border-box;
    display: block;
    text-align: left;

    &-active,
    &:hover {
      &:after {
        position: absolute;
        bottom: 0;
        left: 0;
        height: 0;
        content: '';
      }

      .vab-tabs-more-icon {
        transform: rotate(90deg);

        .box-t {
          &:before {
            transform: rotate(45deg);
          }
        }

        .box:before,
        .box:after {
          background: #206cff;
        }
      }
    }

    &-icon {
      display: inline-block;
      color: var(--el-color-grey);
      cursor: pointer;
      transition: transform 0.3s ease-out;

      .box {
        position: relative;
        display: block;
        width: 14px;
        height: 8px;

        &:before {
          position: absolute;
          top: 2px;
          left: 0;
          width: 6px;
          height: 6px;
          content: '';
          background: rgba(0, 0, 0, 0.65);
        }

        &:after {
          position: absolute;
          top: 2px;
          left: 8px;
          width: 6px;
          height: 6px;
          content: '';
          background: rgba(0, 0, 0, 0.65);
        }
      }

      .box-t {
        &:before {
          transition: transform 0.3s ease-out 0.3s;
        }
      }
    }
  }

  .context-menu {
    position: fixed;
    background: #fff;
    border: 1px solid #ccc;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    z-index: 10000;

    ul {
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        padding: 8px 16px;
        cursor: pointer;

        &:hover {
          background: #f0f0f0;
        }
      }
    }
  }
}
</style>
